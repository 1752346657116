import { ChevronLeftIcon, ChevronRightIcon } from '@storefront/ui-components/shared/icons';
import { useSendEvent } from '@storefront/util/analytics/useAnalytics';
import clsx from 'clsx';
import { type FC, type ReactNode, useEffect } from 'react';
import { type LinkContextValue, LinkFrameworks } from '../../providers/link-provider';
import { usePagination } from './react-use-pagination';

export interface PaginationConfig {
  prefix?: string;
  count: number;
  offset: number;
  limit: number;
}

interface PaginationButtonProps {
  href: string;
}
export interface PaginationProps {
  getNextProps: ({ currentPage }: { currentPage: number }) => PaginationButtonProps;
  getPaginationItemProps: ({ page }: { page: number }) => PaginationButtonProps;
  getPreviousProps: ({ currentPage }: { currentPage: number }) => PaginationButtonProps;
  linkContext?: LinkContextValue;
  paginationConfig: PaginationConfig;
}

export type RenderPaginationItem = FC<{ page: number; className?: string } & React.HTMLProps<HTMLAnchorElement>>;

export type RenderPreviousPaginationButton = FC<
  {
    currentPage: number;
    isDisabled: boolean;
    className?: string;
  } & React.HTMLProps<HTMLAnchorElement>
>;

export type RenderNextPaginationButton = FC<
  {
    page: number;
    isDisabled: boolean;
    className?: string;
  } & React.HTMLProps<HTMLAnchorElement>
>;

export interface PaginationItemProps extends PaginationButtonProps {
  className?: string;
  currentPage: number;
  page: number;
  onClick?: () => void;
  linkContext?: LinkContextValue;
}

const PaginationItem: FC<PaginationItemProps> = ({
  className,
  currentPage,
  page,
  linkContext: LinkContext,
  onClick,
  ...props
}) => {
  const currentClasses = 'z-10 bg-primary-50 border-primary-500 text-primary-600';
  const defaultClasses = 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50';
  const isCurrent = page === currentPage;
  const sendNavigationEvent = useSendEvent('navigation');

  if (LinkContext?.framework === LinkFrameworks.Remix)
    return (
      <LinkContext.Link
        unstable_viewTransition
        className={clsx(
          className,
          'relative inline-flex items-center border px-4 py-2 text-sm font-bold',
          isCurrent ? currentClasses : defaultClasses,
        )}
        aria-current={isCurrent ? 'page' : 'false'}
        to={props.href}
        prefetch="intent"
        onClick={() => {
          if (typeof onClick === 'function') onClick();
          sendNavigationEvent({ type: 'Pagination Clicked', destination: props.href });
        }}
        {...props}
      >
        {page}
      </LinkContext.Link>
    );

  return (
    <a
      className={clsx(
        className,
        'relative inline-flex items-center border px-4 py-2 text-sm font-bold',
        isCurrent ? currentClasses : defaultClasses,
      )}
      aria-current={isCurrent ? 'page' : 'false'}
      {...props}
    >
      {page}
    </a>
  );
};

export interface PaginationArrowButtonProps extends PaginationButtonProps {
  currentPage: number;
  className?: string;
  isDisabled: boolean;
  linkContext?: LinkContextValue;
  children: ReactNode;
  onClick?: () => void;
}

const PaginationButton: FC<PaginationArrowButtonProps> = ({
  currentPage: _currentPage,
  className: _className,
  isDisabled,
  linkContext: LinkContext,
  href,
  onClick,
  ...props
}) => {
  const sendNavigationEvent = useSendEvent('navigation');

  const className = clsx(
    _className,
    { 'pointer-events-none cursor-not-allowed opacity-50': isDisabled },
    'relative inline-flex items-center border border-gray-300 bg-white px-2 py-2 text-sm font-bold text-gray-500 hover:bg-gray-50 sm:px-4',
  );

  if (isDisabled) return <button className={className} disabled aria-disabled={isDisabled} {...props} />;

  if (LinkContext?.framework === LinkFrameworks.Remix)
    return (
      <LinkContext.Link
        unstable_viewTransition
        aria-disabled={isDisabled}
        onClick={() => {
          if (typeof onClick === 'function') onClick();
          sendNavigationEvent({ type: 'Pagination Clicked', destination: href });
        }}
        className={className}
        to={href}
        prefetch="intent"
        {...props}
      />
    );

  return (
    <a
      aria-disabled={isDisabled}
      onClick={(event) => {
        if (isDisabled) event.preventDefault();
      }}
      className={className}
      href={href}
      {...props}
    />
  );
};

export const Pagination: FC<PaginationProps> = ({
  paginationConfig,
  getNextProps,
  getPaginationItemProps,
  getPreviousProps,
  linkContext,
}) => {
  const { totalPages, startIndex, endIndex, setPage } = usePagination({
    totalItems: paginationConfig.count,
    initialPageSize: paginationConfig.limit,
  });

  const currentPage = Math.floor(paginationConfig.offset / paginationConfig.limit) + 1;

  useEffect(() => {
    setPage(currentPage - 1);
  }, [currentPage, setPage]);

  if (paginationConfig.count <= paginationConfig.limit) return null;

  const startPage = totalPages <= 5 ? 1 : Math.max(1, currentPage - 1);
  const endPage = totalPages <= 5 ? totalPages : Math.min(totalPages, currentPage + 1);

  return (
    <div className="mt-16 flex items-center justify-between border-t border-gray-200 py-3">
      <div className="flex flex-1 flex-col-reverse flex-wrap items-center justify-between gap-4 sm:flex-row">
        <div>
          <p className="mb-4 text-sm text-gray-700 sm:mb-0">
            Showing <span className="font-bold">{startIndex + 1}</span> to{' '}
            <span className="font-bold">{endIndex + 1}</span> of{' '}
            <span className="font-bold">{paginationConfig.count}</span> results
          </p>
        </div>
        <div>
          <nav className="relative z-0 inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
            <PaginationButton
              className="rounded-l-md"
              currentPage={currentPage}
              isDisabled={currentPage === 1}
              linkContext={linkContext}
              {...getPreviousProps({ currentPage })}
            >
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </PaginationButton>

            {startPage > 2 && (
              <>
                <PaginationItem
                  page={1}
                  currentPage={currentPage}
                  linkContext={linkContext}
                  {...getPaginationItemProps({ page: 1 })}
                />
                <span className="relative inline-flex items-center border border-gray-300 bg-white px-3 py-2 text-sm font-bold text-gray-700 sm:px-4">
                  ...
                </span>
              </>
            )}

            {Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i).map((page) => (
              <PaginationItem
                key={page}
                page={page}
                currentPage={currentPage}
                linkContext={linkContext}
                {...getPaginationItemProps({ page })}
              />
            ))}

            {endPage < totalPages - 1 && (
              <>
                <span className="relative inline-flex items-center border border-gray-300 bg-white px-3 py-2 text-sm font-bold text-gray-700 sm:px-4">
                  ...
                </span>
                <PaginationItem
                  page={totalPages}
                  currentPage={currentPage}
                  linkContext={linkContext}
                  {...getPaginationItemProps({ page: totalPages })}
                />
              </>
            )}

            <PaginationButton
              className="rounded-r-md"
              currentPage={currentPage}
              isDisabled={currentPage === totalPages}
              {...getNextProps({ currentPage })}
            >
              <span className="sr-only">Next</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </PaginationButton>
          </nav>
        </div>
      </div>
    </div>
  );
};
